<template>
    <div>
        <!-- 顶部按钮 -->
        <div class="topbtns">
            <el-button icon="el-icon-zoom-in" type="primary" size="medium" @click="compilewhite('add')">
                添加白名单
            </el-button>
        </div>
        <el-table :data="riskWhiteList" v-loading="loading" :header-cell-style="{ background: '#f0f0f0', color: '#666' }">
            <el-table-column align="center" type="index" prop="UserID" label="序号" width="50"
                show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="EnterpriseFullName" label="企业名称" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="EnterpriseCreditCode" label="企业统一社会信用代码"
                show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="StartTime" label="开始日期" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="EndTime" label="结束日期" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="IsSkipPercent2" label="普票费率限制" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-tag :type="scope.row.IsSkipPercent2 ? 'danger' : 'success'">{{ scope.row.IsSkipPercent2 ? '限制' :
                        '不限制' }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="240">
                <template slot-scope="scope">
                    <el-button type="primary" size="medium" @click="compilewhite('compile', scope.row)"
                        icon="el-icon-edit">编辑</el-button>
                    <el-button type="danger" size="medium" @click="delwhite(scope.row)" icon="el-icon-delete">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 白名单弹窗 -->
        <el-dialog class="addDialog" width="1200px" :visible.sync="isWhite">
            <span slot="title" class="dialog-title">
                编辑白名单
            </span>
            <!-- 搜索 -->
            <el-form v-if="modelType == 'add'" class="manageForm" center :model="searchData" :inline="true"
                label-width="70px">
                <el-form-item label="企业账号" prop="phone">
                    <el-input class="fromInp" v-model="searchData.phone" placeholder="企业账号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="medium" @click="searchFrom()" icon="el-icon-search">搜索</el-button>
                    <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete">清空</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="whitelist" :header-cell-style="{ background: '#f0f0f0', color: '#666' }">
                <el-table-column align="center" type="index" prop="UserID" label="序号" width="50"
                    show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="EnterpriseFullName" label="企业名称"
                    show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="EnterpriseCreditCode" label="企业统一社会信用代码"
                    show-overflow-tooltip></el-table-column>
                <!-- <el-table-column align="center" prop="EnterpriseAddress" label="企业地址" width="280" show-overflow-tooltip></el-table-column> -->
                <!-- <el-table-column align="center" prop="LegalPersonIName" label="姓名" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="LegalPersonIIDCard" label="身份证号" width="160" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="LegalPersonlPhone" label="手机号" width="120" show-overflow-tooltip></el-table-column> -->
                <el-table-column align="center" prop="dateTime" label="起始日期">
                    <template slot-scope="scope">
                        <el-date-picker :picker-options="pickerOptions" value-format="yyyy-MM-dd" v-model="dateTime"
                            type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                        </el-date-picker>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="IsSkipPercent2" label="普票费率限制">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.IsSkipPercent2" inactive-text="不限制" active-text="限制">
                        </el-switch>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelModel()">取 消</el-button>
                <el-button type="primary" :disabled="dateTime == '' ? true : false" @click="addWhite()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { GetEnterList, addWhiteList, DeleteRiskWhite } from "@/api/configFlie/index";
export default {
    props: ["riskWhiteList", "loading"],
    data() {
        return {
            IsSkipPercent2: false,
            // 选择日期 不能选择当日之前日期
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7 // 设置选择今天以及今天之后的日
                }
            },
            // 添加白名单弹窗
            isWhite: false,
            // 搜索参数
            searchData: {
                phone: ''
            },
            // 搜索的数据
            whitelist: [],
            // 起始日期
            dateTime: [],
            // 弹窗类型
            modelType: ''
        }
    },
    watch: {
        isWhite(newValue) {
            if (newValue == false) {
                this.dateTime = []
                this.whitelist = []
            }
        }
    },
    methods: {
        // 删除
        delwhite(item) {
            this.$confirm('是否从白名单中删除该企业?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                DeleteRiskWhite({ ID: item.ID }).then(res => {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.$emit('GetRiskWhiteList')
                })
            })
        },
        // 编辑
        compilewhite(type, item) {
            this.modelType = type
            if (type == 'compile') {
                this.whitelist = [item]
                this.dateTime = [item.StartTime, item.EndTime]
            }
            this.isWhite = true
        },
        // 搜索企业
        searchFrom() {
            GetEnterList({ Phone: this.searchData.phone }).then(res => {
                this.whitelist = res.data
            })
        },
        // 添加白名单
        addWhite() {
            let data = {
                UserID: this.whitelist[0].UserID,
                StartTime: this.dateTime[0],
                EndTime: this.dateTime[1],
                IsSkipPercent2: this.whitelist[0].IsSkipPercent2
            }
            if (this.modelType == 'compile') {
                data.ID = this.whitelist[0].ID
            }
            addWhiteList({ Json: JSON.stringify(data) }).then(res => {
                this.$message({
                    message: '编辑成功',
                    type: 'success'
                });
                this.$emit('GetRiskWhiteList', this.modelType)
                this.isWhite = false
            })
        },
        // 隐藏弹窗
        cancelModel() {
            this.searchData = {
                phone: ''
            }
            this.isWhite = false
        }
    }
}
</script>

<style lang="scss">
.addDialog {
    .el-date-editor--daterange.el-input__inner {
        width: 250px;
    }
}
</style>

<style lang="scss" scoped>
.topbtns {
    padding-bottom: 10px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid #eee;
}
</style>